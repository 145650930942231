import React from "react";
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

import SignIn from "views/auth/SignIn";

import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdToll,
} from "react-icons/md";
import Variant from "views/admin/marketplace/variant";
import Banner from "views/admin/marketplace/Banner";

const routes = [
  {
    name: "Manage Category",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Manage Product",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Manage Orders",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Manage Users",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Manage Variant",
    layout: "/admin",
    path: "Variant",
    icon: <MdToll className="h-6 w-6" />,
    component: <Variant/>,
  },
  {
    name: "Manage Banner",
    layout: "/admin",
    path: "Banner",
    icon: <MdHome className="h-6 w-6" />,
    component: <Banner/>,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
