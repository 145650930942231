// import React, { useState, useEffect } from 'react';
// import Card from 'components/card';
// import { BaseUrl } from 'BaseUrl';
// import { FaEdit, FaTrash } from 'react-icons/fa';
// import axios from 'axios';

// const Variant = () => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [editingVariantId, setEditingVariantId] = useState(null);
//   const [variants, setVariants] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [formData, setFormData] = useState({
//     productId: '',
//     weight: '',
//     price: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formDataToSend = new URLSearchParams();
//     for (let key in formData) {
//       formDataToSend.append(key, formData[key]);
//     }

//     try {
//       const response = await fetch(`${BaseUrl}/admin/add-variant`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: formDataToSend,
//       });

//       if (response.ok) {
//         console.log('Variant added successfully');
//         setFormData({
//           productId: '',
//           weight: '',
//           price: '',
//         });
//         fetchVariants(); // Fetch variants again after adding a new one
//       } else {
//         console.error('Failed to add variant');
//       }
//     } catch (error) {
//       console.error('Error adding variant:', error);
//     }
//   };

//   const fetchProducts = async () => {
//     try {
//       const response = await fetch(`${BaseUrl}/admin/get-products`);
//       if (response.ok) {
//         const data = await response.json();
//         setProducts(data.data);
//       } else {
//         console.error('Failed to fetch products');
//       }
//     } catch (error) {
//       console.error('Error fetching products:', error);
//     }
//   };

//   const fetchVariants = async () => {
//     try {
//       const response = await fetch(`${BaseUrl}/admin/get-variants`);
//       if (response.ok) {
//         const data = await response.json();
//         setVariants(data.data);
//       } else {
//         console.error('Failed to fetch variants');
//       }
//     } catch (error) {
//       console.error('Error fetching variants:', error);
//     }
//   };

//   useEffect(() => {
//     fetchProducts();
//     fetchVariants();
//   }, []);

//   const handleDelete = async (variantId) => {
//     try {
//       const response = await fetch(`${BaseUrl}/admin/delete-variant/${variantId}`, {
//         method: 'DELETE',
//       });

//       if (response.ok) {
//         console.log('Variant deleted successfully');
//         setVariants(variants.filter((variant) => variant._id !== variantId));
//       } else {
//         console.error('Failed to delete variant');
//       }
//     } catch (error) {
//       console.error('Error deleting variant:', error);
//     }
//   };

//   const handleEdit = async (variantId) => {
//     try {
//       const response = await axios.get(`${BaseUrl}/admin/get-variant/${variantId}`);
//       if (response.data.success) {
//         const variantData = response.data.data;
//         setFormData({
//           productId: variantData.productId,
//           weight: variantData.weight,
//           price: variantData.price,
//         });
//         setIsEditing(true);
//         setEditingVariantId(variantId);
//       } else {
//         console.error("Error: Variant not found");
//       }
//     } catch (error) {
//       console.error("Error fetching variant data:", error);
//     }
//   };

//   return (
//     <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2">
//       <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
//         <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
//           <select name="productId" value={formData.productId} onChange={handleChange} style={{ marginBottom: '10px' }}>
//             <option value="">Select Product</option>
//             {products.map((product) => (
//               <option key={product._id} value={product._id}>{product.productName}</option>
//             ))}
//           </select>
//           <input type="text" name="weight" value={formData.weight} onChange={handleChange} placeholder="Weight" style={{ marginBottom: '10px' }} />
//           <input type="text" name="price" value={formData.price} onChange={handleChange} placeholder="Price" style={{ marginBottom: '10px' }} />
//           <button type="submit" style={{ backgroundColor: 'blue', color: 'white', padding: '10px', borderRadius: '5px', cursor: 'pointer' }}>Add Variant</button>
//         </form>
//         <Card extra={'w-full h-full px-6 pb-6 sm:overflow-x-auto'}>
//           <div className="relative flex items-center justify-between pt-4">
//             <div className="text-xl font-bold text-navy-700 dark:text-white">Manage Variants Table</div>
//           </div>
//           <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
//             <table className="min-w-full divide-y divide-gray-200">
//               <thead className="bg-gray-50">
//                 <tr>
//                   <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Product ID
//                   </th>
//                   <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Weight
//                   </th>
//                   <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Price
//                   </th>
//                   <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Actions
//                   </th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white divide-y divide-gray-200">
//                 {variants.map((variant) => (
//                   <tr key={variant._id}>
//                     <td className="px-6 py-4 whitespace-nowrap">{variant.productId}</td>
//                     <td className="px-6 py-4 whitespace-nowrap">{variant.weight}</td>
//                     <td className="px-6 py-4 whitespace-nowrap">{variant.price}</td>
//                     <td className="px-6 py-4 whitespace-nowrap">
//                       <div className="flex">
//                         <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(variant._id)} />
//                         <FaTrash style={{ cursor: 'pointer' }} onClick={() => handleDelete(variant._id)} />
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </Card>
//       </div>
//     </div>
//   );
// };

// export default Variant;


import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import { BaseUrl } from 'BaseUrl';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';

const Variant = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingVariantId, setEditingVariantId] = useState(null);
  const [variants, setVariants] = useState([]);
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    productId: '',
    weight: '',
    price: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new URLSearchParams();
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const url = isEditing
        ? `${BaseUrl}/admin/update-variant/${editingVariantId}`
        : `${BaseUrl}/admin/add-variant`;

      const method = isEditing ? 'PUT' : 'POST';

      const response = await axios({
        method,
        url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: formDataToSend,
      });

      if (response.data.success) {
        console.log(`Variant ${isEditing ? 'updated' : 'added'} successfully`);
        setFormData({
          productId: '',
          weight: '',
          price: '',
        });
        setIsEditing(false);
        setEditingVariantId(null);
        fetchVariants(); // Fetch variants again after adding/updating
      } else {
        console.error(`Failed to ${isEditing ? 'update' : 'add'} variant`);
      }
    } catch (error) {
      console.error(`Error ${isEditing ? 'updating' : 'adding'} variant:`, error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${BaseUrl}/admin/get-products`);
      if (response.ok) {
        const data = await response.json();
        setProducts(data.data);
      } else {
        console.error('Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchVariants = async () => {
    try {
      const response = await fetch(`${BaseUrl}/admin/get-variants`);
      if (response.ok) {
        const data = await response.json();
        setVariants(data.data);
      } else {
        console.error('Failed to fetch variants');
      }
    } catch (error) {
      console.error('Error fetching variants:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchVariants();
  }, []);

  const handleDelete = async (variantId) => {
    try {
      const response = await fetch(`${BaseUrl}/admin/delete-variant/${variantId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log('Variant deleted successfully');
        setVariants(variants.filter((variant) => variant._id !== variantId));
      } else {
        console.error('Failed to delete variant');
      }
    } catch (error) {
      console.error('Error deleting variant:', error);
    }
  };

  const handleEdit = async (variantId) => {
    try {
      const response = await axios.get(`${BaseUrl}/admin/get-variant/${variantId}`);
      if (response.data.success) {
        const variantData = response.data.data;
        setFormData({
          productId: variantData.productId,
          weight: variantData.weight,
          price: variantData.price,
        });
        setIsEditing(true);
        setEditingVariantId(variantId);
      } else {
        console.error("Error: Variant not found");
      }
    } catch (error) {
      console.error("Error fetching variant data:", error);
    }
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
          <select name="productId" value={formData.productId} onChange={handleChange} style={{ marginBottom: '10px' }}>
            <option value="">Select Product</option>
            {products.map((product) => (
              <option key={product._id} value={product._id}>{product.productName}</option>
            ))}
          </select>
          <input type="text" name="weight" value={formData.weight} onChange={handleChange} placeholder="Weight" style={{ marginBottom: '10px' }} />
          <input type="text" name="price" value={formData.price} onChange={handleChange} placeholder="Price" style={{ marginBottom: '10px' }} />
          <button type="submit" style={{ backgroundColor: 'blue', color: 'white', padding: '10px', borderRadius: '5px', cursor: 'pointer' }}>
            {isEditing ? 'Update Variant' : 'Add Variant'}
          </button>
        </form>
        <Card extra={'w-full h-full px-6 pb-6 sm:overflow-x-auto'}>
          <div className="relative flex items-center justify-between pt-4">
            <div className="text-xl font-bold text-navy-700 dark:text-white">Manage Variants Table</div>
          </div>
          <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Weight
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {variants.map((variant) => (
                  <tr key={variant._id}>
                    <td className="px-6 py-4 whitespace-nowrap">{variant.productId}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{variant.weight}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{variant.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex">
                        <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(variant._id)} />
                        <FaTrash style={{ cursor: 'pointer' }} onClick={() => handleDelete(variant._id)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Variant;
