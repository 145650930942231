import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "components/card";
import { useMemo } from "react";
import { MdEdit, MdDelete, MdArrowDropUp } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { BaseUrl } from "BaseUrl";


const ComplexTable = () => {
  const [tableData, setTableData] = useState([]);
  const [id, setid] = useState([]);
  const [Show, setshow] = useState(true);
  const [hide, sethide] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
  });
  const [addData, setaddData] = useState({
    name: "",
    description: "",
    image: null,
  });
  const columnsData = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Image",
        accessor: "image",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-4">
            <button
              className="text-blue-500 hover:text-blue-700"
              onClick={() => handleEdit(row.original._id)}
            >
              <MdEdit />
            </button>
            <button
              className="text-red-500 hover:text-red-700 m-2"
              onClick={() => handleDelete(row.original._id)}
            >
              <MdDelete />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setaddData({ ...addData, [name]: files[0] });
    } else {
      setaddData({ ...addData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataApi = new FormData();
      formDataApi.append("name", addData.name);
      formDataApi.append("description", addData.description);
      formDataApi.append("image", addData.image);

      const response = await axios.post(
        `${BaseUrl}/admin/create-categories`,
        formDataApi,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setaddData({ name: "", description: "", image: null });
      fetchCategories();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/admin/get-categories`);
      setTableData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`${BaseUrl}/admin/get-categories/${id}`);
      if (response.data) {
        const categoryData = response.data.data;
        setFormData({
          name: categoryData.name,
          description: categoryData.description,
          image: categoryData.image,
        });
  
        setid(id);
        setshow(false);
        sethide(true)
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  const handleUpdateChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataApi = new FormData();
      formDataApi.append("name", formData.name);
      formDataApi.append("description", formData.description);
      formDataApi.append("image", formData.image);
      
      const response = await axios.put(
        `${BaseUrl}/admin/update-categories/${id}`,
        formDataApi,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setFormData({ name: "", description: "", image: null });
      sethide(false)
      setshow(true);
      fetchCategories();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseUrl}/admin/delete-categories/${id}`);
      setTableData((prevData) =>
        prevData.filter((row) => row._id !== id)
      );
      console.log("Category deleted successfully");
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  return (
    <><>
     <div className="mt-5 grid grid-cols-5 gap-5 md:grid-cols-2">
       {!hide && (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          <MdArrowDropUp />
          <span className="text-sm font-medium text-gray-600">Add Category</span>
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
          <div className="flex flex-col">
            <input
              type="text"
              name="name"
              placeholder="Category Name"
              value={addData.name}
              onChange={handleChange} />
            <input
              type="text"
              name="description"
              placeholder="Category Description"
              value={addData.description}
              onChange={handleChange} />
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleChange} />

            <div>
              <button
                type="submit"
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </div>

        </div>
      </form>
    </Card>
       )}
    {!Show && (
      <Card extra="!p-[20px] text-center">
        <div className="flex justify-between">
          <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
            <MdArrowDropUp />
            <span className="text-sm font-medium text-gray-600">Update Category</span>
          </button>
        </div>
        <form onSubmit={handleUpdateSubmit}>
          <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
            <div className="flex flex-col">
              <input
                type="text"
                name="name"
                placeholder="Category Name"
                value={formData.name}
                onChange={handleUpdateChange} />
              <input
                type="text"
                name="description"
                placeholder="Category Description"
                value={formData.description}
                onChange={handleUpdateChange} />
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleUpdateChange} />

              <div>
                <button
                  type="submit"
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    )}
      </div></>
      {!hide && (
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Manage Category Table
          </div>
        </div>

        <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                    >
                      <p className="text-xs tracking-wide text-gray-600">
                        {column.render("Header")}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = cell.render("Cell");
                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
      )}
      </>
  );
};

export default ComplexTable;
