import CheckTable from "./components/CheckTable";

import {
  columnsDataCheck,
} from "./variables/columnsData";
import tableDataCheck from "./variables/tableDataCheck.json";

const Tables = () => {
  return (
    <div className="mt-5 grid">
    <div className=" bg-gray-200 rounded-lg shadow-md">
      <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
    </div>
  </div>
  );
};

export default Tables;
