import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "components/card";
import { FaTrash } from "react-icons/fa"; // Import Edit and Delete icons
import { BaseUrl } from "BaseUrl";

const ProfileOverview = () => {
  const [users, setUsers] = useState([]); // State to store users data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
         `${BaseUrl}/admin/getAllUsersList`
        );
        console.log("API Response:", response.data); 
        setUsers(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseUrl}/admin/deleteUserbyadmin/${id}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Manage Users Table
          </div>
        </div>
        <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User_ID</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user._id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.createdAt}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex">
                      <FaTrash style={{ cursor: 'pointer' }} onClick={() => handleDelete(user._id)} /> {/* Delete Icon */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default ProfileOverview;

