import { columnsDataComplex } from "./variables/columnsData";
import ComplexTable from "views/admin/default/components/ComplexTable";
import tableDataComplex from "./variables/tableDataComplex.json";

const Dashboard = () => {
  return (
    <div>
      {/* Card widget */}

      {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={"$340.5"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Spend this month"}
          subtitle={"$642.39"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Sales"}
          subtitle={"$574.34"}
        />
      </div> */}

      {/* Charts */}

      <div className="mt-5 grid grid-cols-5 gap-5 md:grid-cols-2">
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
      </div>


      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
  <div>
  </div>
  <div className="col-span-full">
    <ComplexTable
      columnsData={columnsDataComplex}
      tableData={tableDataComplex}
    />
  </div>
</div>

    </div>
  );
};

export default Dashboard;
