import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "components/card";
import { FaTrash } from "react-icons/fa"; // Import Delete icon
import { BaseUrl } from "BaseUrl";

const CheckTable = () => {
  const [orders, setOrders] = useState([]); // State to store orders data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/user/get-all-orders`
        );
        console.log("API Response:", response.data);
        setOrders(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BaseUrl}/admin/delete-order/${id}`);
      setOrders((prevOrders) => prevOrders.filter((order) => order._id !== id));
      console.log("Order deleted successfully");
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Manage Orders Table
          </div>
        </div>
        <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User ID</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Items</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Date</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Price</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders.map((order) => (
                <tr key={order._id}>
                  <td className="px-6 py-4 whitespace-nowrap">{order.user_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <ul>
                      {order.items.map((item) => (
                        <li key={item._id}>
                         {item.product_id},  {item.quantity},  {item.total_price}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{order.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{order.orderDate}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{order.total_price}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{order.createdAt}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex">
                      <FaTrash style={{ cursor: 'pointer' }} onClick={() => handleDelete(order._id)} /> {/* Delete Icon */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default CheckTable;
