import React, { useState, useEffect } from "react";
import Card from "components/card";
import { BaseUrl } from "BaseUrl";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";

const Marketplace = () => {
  const [id, setid] = useState([]);
  const [Show, setshow] = useState(true);
  const [hide, sethide] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
 
  const [formData, setFormData] = useState({
    productName: "",
    description: "",
    category_id: "",
    discount: "",
    price:"",
    weight: "",
    image1: null,
    image2: null,
    image3: null
  });

  const [updateData, setUpdateData] = useState({
    productName: "",
    description: "",
    category_id: "",
    discount: "",
    price:"",
    weight: "",
    image1: null,
    image2: null,
    image3: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'discount') {
        const regex = /^(?:[1-9]|[1-9][0-9])$/;
        if (regex.test(value) || value === '') {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    } else {
        setFormData({
            ...formData,
            [name]: value
        });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await fetch(`${BaseUrl}/admin/add-products`, {
        method: 'POST',
        body: formDataToSend
      });

      if (response.ok) {
        console.log('Product added successfully');
        setFormData({
          productName: "",
          description: "",
          category_id: "",
          discount: "",
          price:"",
          weight: "",
          image1: null,
          image2: null,
          image3: null
        });
        // Refresh the product list after adding a new product
        fetchProducts();
      } else {
        console.error('Failed to add product');
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${BaseUrl}/admin/get-products`);
      if (response.ok) {
        const data = await response.json();
        setProducts(data.data);
      } else {
        console.error('Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${BaseUrl}/admin/get-categories`);
        if (response.ok) {
          const data = await response.json();
          setCategories(data.data); 
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'discount') {
        const regex = /^(?:[1-9]|[1-9][0-9])$/;
        if (regex.test(value) || value === '') {
            setUpdateData({
                ...updateData,
                [name]: value
            });
        }
    } else {
        setUpdateData({
            ...updateData,
            [name]: value
        });
    }
  };

  const handleUpdateFileChange = (e) => {
    const { name, files } = e.target;
    setUpdateData({
      ...updateData,
      [name]: files[0]
    });
  };

  const handleupdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataApi = new FormData();
      for (let key in updateData) {
        formDataApi.append(key, updateData[key]);
      }
      const response = await axios.put(
        `${BaseUrl}/admin/update-products/${id}`,
        formDataApi,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      sethide(false);
      setshow(true);
      // Refresh the product list after updating a product
      fetchProducts();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (productId) => {
    try {
      const response = await fetch(`${BaseUrl}/admin/delete-products/${productId}`, {
        method: 'DELETE'
      });

      if (response.ok) {
        console.log('Product deleted successfully');
        setProducts(products.filter(product => product._id !== productId));
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`${BaseUrl}/admin/get-products/${id}`);
      if (response.data.success) {
        const productData = response.data.data;
        setUpdateData({
          productName: productData.productName,
          description: productData.description,
          category_id: productData.category_id,
          discount: productData.discount,
          price: productData.price,
          weight: productData.weight,
          image1: productData.image1,
          image2: productData.image2,
          image3: productData.image3,
        });

        setid(id);
        setshow(false);
        sethide(true);
      } else {
        console.error("Error: Product not found");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 ">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {!hide && (
          <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
            <input type="text" name="productName" value={formData.productName} onChange={handleChange} placeholder="Product Name" style={{ marginBottom: '10px' }} />
            <input type="text" name="price" value={formData.price} onChange={handleChange} placeholder="Price" style={{ marginBottom: '10px' }} />
            <input type="text" name="description" value={formData.description} onChange={handleChange} placeholder="Description" style={{ marginBottom: '10px' }} />
            <select name="category_id" value={formData.category_id} onChange={handleChange} style={{ marginBottom: '10px' }}>
              <option value="">Select Category</option>
              {categories.map(category => (
                <option key={category._id} value={category._id}>{category.name}</option>
              ))}
            </select>
            <input
              type="text"
              name="discount"
              value={formData.discount}
              onChange={handleChange}
              placeholder="Discount"
              style={{ marginBottom: '10px' }}
            />
            <input
              type="text"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              placeholder="Weight"
              style={{ marginBottom: '10px' }}
            />
            <input type="file" name="image1" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
            <input type="file" name="image2" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
            <input type="file" name="image3" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
            <button type="submit" style={{ backgroundColor: 'blue', color: 'white', padding: '10px', borderRadius: '5px', cursor: 'pointer' }}>Add Product</button>
          </form>
        )}
        {!Show && (
          <form onSubmit={handleupdateSubmit} style={{ margin: '20px' }}>
            <input type="text" name="productName" value={updateData.productName} onChange={handleUpdateChange} placeholder="Product Name" style={{ marginBottom: '10px' }} />
            <input type="text" name="price" value={updateData.price} onChange={handleUpdateChange} placeholder="Price" style={{ marginBottom: '10px' }} />
            <input type="text" name="description" value={updateData.description} onChange={handleUpdateChange} placeholder="Description" style={{ marginBottom: '10px' }} />
            <select name="category_id" value={updateData.category_id} onChange={handleUpdateChange} style={{ marginBottom: '10px' }}>
              <option value="">Select Category</option>
              {categories.map(category => (
                <option key={category._id} value={category._id}>{category.name}</option>
              ))}
            </select>
            <input
              type="text"
              name="discount"
              value={updateData.discount}
              onChange={handleUpdateChange}
              placeholder="Discount"
              style={{ marginBottom: '10px' }}
            />
            <input
              type="text"
              name="weight"
              value={updateData.weight}
              onChange={handleUpdateChange}
              placeholder="Weight"
              style={{ marginBottom: '10px' }}
            />
            <input type="file" name="image1" onChange={handleUpdateFileChange} style={{ marginBottom: '10px' }} />
            <input type="file" name="image2" onChange={handleUpdateFileChange} style={{ marginBottom: '10px' }} />
            <input type="file" name="image3" onChange={handleUpdateFileChange} style={{ marginBottom: '10px' }} />
            <button type="submit" style={{ backgroundColor: 'blue', color: 'white', padding: '10px', borderRadius: '5px', cursor: 'pointer' }}>Update Product</button>
          </form>
        )}
      </div>

      <div className="grid grid-cols-3 gap-4 xl:grid-cols-3 2xl:grid-cols-3">
        {products.map((product) => (
          <div key={product._id} className="relative w-full">
            <Card extra="!flex-row flex-grow items-center rounded-[20px] w-full">
              <div className="mb-3 h-full w-1/2">
                <img src={product.image1} className="h-full w-full rounded-xl" alt="Product" />
              </div>
              <div className="ml-3 flex w-1/2 flex-col">
                <p className="text-base font-medium text-navy-700">{product.productName}</p>
                <p className="mt-1 text-sm text-gray-600">{product.price}</p>
                <p className="mt-1 text-sm text-gray-600">{product.description}</p>
                <p className="mt-1 text-sm text-gray-600">{product.discount}%</p>
                <p className="mt-1 text-sm text-gray-600">{product.weight} kg</p>
                <div className="mt-3 flex items-center justify-between">
                  <button onClick={() => handleEdit(product._id)} className="text-gray-600 hover:text-blue-500">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDelete(product._id)} className="text-gray-600 hover:text-red-500">
                    <FaTrash />
                  </button>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marketplace;

