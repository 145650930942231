import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import Card from 'components/card';
import { BaseUrl } from 'BaseUrl';

const AddBannerForm = () => {
  const [bannerData, setBannerData] = useState(null);

  const [formData, setFormData] = useState({
    image1: null,
    image2: null,
    image3: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('image1', formData.image1);
    formDataToSend.append('image2', formData.image2);
    formDataToSend.append('image3', formData.image3);

    try {
      const response = await axios.post(
        `${BaseUrl}/admin/addBanner`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('API Response:', response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/admin/getBanner`
        );
        setBannerData(response.data.data);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    fetchBannerData();
  }, []);

  const handleDelete = async (id) => {
    console.log(id,'banner_id');
    try {
      const response = await fetch(`${BaseUrl}/admin/delete-variant/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log('Variant deleted successfully');
        // setVariants(variants.filter((variant) => variant._id !== variantId));
      } else {
        console.error('Failed to delete variant');
      }
    } catch (error) {
      console.error('Error deleting variant:', error);
    }
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
         <input
        type="file"
        name="image1"
        onChange={handleFileChange}
        style={{ marginBottom: '10px' }}
      />
      <input
        type="file"
        name="image2"
        onChange={handleFileChange}
        style={{ marginBottom: '10px' }}
      />
      <input
        type="file"
        name="image3"
        onChange={handleFileChange}
        style={{ marginBottom: '10px' }}
      />
      <button
        type="submit"
        style={{
          backgroundColor: 'blue',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Add Banner
      </button>
    </form>
        <Card extra={'w-full h-full px-6 pb-6 sm:overflow-x-auto'}>
          <div className="relative flex items-center justify-between pt-4">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Manage Banner Table
            </div>
          </div>
          <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product ID
                  </th> */}
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image 1
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image 2
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image 3
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Banner ID
                  </th>
                   <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {bannerData && ( 
                  <tr key={bannerData._id}>
                    {/* <td className="px-6 py-4 whitespace-nowrap">{bannerData.productId}</td> */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      {bannerData.image1 && <img className="h-8 w-8 mr-2" src={bannerData.image1} alt="" />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {bannerData.image2 && <img className="h-8 w-8 mr-2" src={bannerData.image2} alt="" />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {bannerData.image3 && <img className="h-8 w-8 mr-2" src={bannerData.image3} alt="" />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{bannerData.banner_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex">
                        <FaEdit style={{ cursor: 'pointer' }} onClick={() => handleDelete(bannerData.banner_id)} />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddBannerForm;
